/* roboto-100normal - greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Roboto Thin'),
    local('Roboto-Thin'), 
    url('./files/roboto-greek-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/roboto-greek-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-100italic - greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Roboto Thin Italic'),
    local('Roboto-ThinItalic'), 
    url('./files/roboto-greek-100-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/roboto-greek-100-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-300normal - greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Light'),
    local('Roboto-Light'), 
    url('./files/roboto-greek-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/roboto-greek-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-300italic - greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Light Italic'),
    local('Roboto-LightItalic'), 
    url('./files/roboto-greek-300-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/roboto-greek-300-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-400normal - greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto'),
    local('Roboto-Regular'), 
    url('./files/roboto-greek-400.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/roboto-greek-400.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-400italic - greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Italic'),
    local('Roboto-Italic'), 
    url('./files/roboto-greek-400-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/roboto-greek-400-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500normal - greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Medium'),
    local('Roboto-Medium'), 
    url('./files/roboto-greek-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/roboto-greek-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500italic - greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Medium Italic'),
    local('Roboto-MediumItalic'), 
    url('./files/roboto-greek-500-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/roboto-greek-500-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700normal - greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Bold'),
    local('Roboto-Bold'), 
    url('./files/roboto-greek-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/roboto-greek-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700italic - greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Bold Italic'),
    local('Roboto-BoldItalic'), 
    url('./files/roboto-greek-700-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/roboto-greek-700-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-900normal - greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Roboto Black'),
    local('Roboto-Black'), 
    url('./files/roboto-greek-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/roboto-greek-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-900italic - greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Roboto Black Italic'),
    local('Roboto-BlackItalic'), 
    url('./files/roboto-greek-900-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/roboto-greek-900-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
